/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>🔥 Per petició vostra... JA EL TENIM AQUÍ! 🔥</strong>
                </p>

                <p>
                    Un curs intensiu i especialitzat per superar amb èxit la fase psicoprofessional
                    de l’oposició! 🚀
                </p>

                <p>
                    <strong>📅 Data:</strong> Diumenge 20 d'abril
                </p>
                <p>
                    <strong>⏰ Horari:</strong> De 9.00 a 14.00h
                </p>
                <p>
                    <strong>📍 Modalitat:</strong> Online en directe via <strong>ZOOM</strong>
                </p>
                <p>
                    <strong>👨‍🏫 Impartit per:</strong> Psicòleg i docent especialista
                </p>

                <p>
                    <strong>📝 Què treballarem durant el curs?</strong>
                </p>
                <ul>
                    <li>
                        ✅ <strong>Confecció del BIODATA</strong> i conceptes clau per destacar.
                    </li>
                    <li>
                        ✅ <strong>Estructura del CV i imatge personal</strong>: com presentar-te de
                        manera impecable.
                    </li>
                    <li>
                        ✅ <strong>Anàlisi del perfil policial</strong> i com ajustar el teu
                        enfocament.
                    </li>
                    <li>
                        ✅ <strong>Introducció als tests psicoprofessionals</strong>: explicacions
                        clares i consells pràctics.
                    </li>
                    <li>
                        ✅ <strong>Especial atenció al test psicoprofessional oficial</strong> dels
                        últims anys.
                    </li>
                </ul>

                <p>
                    📖 <strong>Inclou dossier en PDF</strong> per a la preparació personalitzada de
                    cada alumne.
                </p>

                <p>
                    <strong>💰 Preu exclusiu:</strong> Només <strong>69,99 €</strong>!
                </p>

                <p>
                    🔹 No deixis escapar aquesta oportunitat única per preparar-te amb professionals
                    i assegurar el teu èxit en les proves psicoprofessionals. 💪
                </p>

                <p>
                    <strong>📌 Inscripcions obertes! Reserva la teva plaça ara!</strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Domina la fase psicoprofessional de l’oposició amb un curs intensiu de 5 hores en directe! 🚀 Aprèn dels millors, prepara’t amb material exclusiu i assegura el teu èxit per només 69,99 €!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
